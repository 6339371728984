import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import {first} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Language} from '../../../models/language.model';
import {TranslateService} from '@ngx-translate/core';
import {AuthenticationService, IdleTimeoutService} from '@pwc/security';
import {User} from '@pwc/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input()
  user: User = null;
  users: User[] = [];
  userInitials: string = '';

  @Input() languages: Language[] = [];
  @Input() currentLanguage: string;
  @Output() updateLanguage = new EventEmitter<string>();

  constructor(private appComponent: AppComponent,
              private route: ActivatedRoute,
              protected translateService: TranslateService,
              private router: Router,
              private idleService: IdleTimeoutService,
              private authenticationService: AuthenticationService) {
    this.idleService.start();
  }

  ngOnInit(): void {
    this.initLoggedUser();
  }

  logout(): void {
    this.appComponent.logout();
  }

  navigateToChangePassword(): void {
    this.router.navigate(['account', 'change-password']);
  }

  initLoggedUser(): void {
    this.authenticationService.dataUser()
      .pipe(first())
      .subscribe(
        data => {
          this.user = data;
          this.users.push(this.user);
          // console.log('data', data);
          if (this.user !== null && this.user.firstName != null) {
            this.userInitials += this.user.firstName.substr(0, 1);
          }
          if (this.user !== null && this.user.lastName != null) {
            this.userInitials += this.user.lastName.substr(0, 1);
          }
          if (this.user === null) {
            this.user = new User();
          }
          if (this.currentLanguage !== this.user.language) {
            this.currentLanguage = this.user.language;
            this.updateLanguage.emit(this.currentLanguage);
          }
        },
        error => {
          console.error(error);
        });
  }

  changeLanguage(): void {
    this.updateLanguage.emit(this.currentLanguage);
  }
}
