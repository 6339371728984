import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RedirectService } from '@pwc/common';
import { Language, LanguageCode } from '@pwc/i18n';
import { AuthenticationCookie, AuthenticationService, CryptoService } from '@pwc/security';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { catchError, first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TraceabilityProcessEnum } from '../../enums/traceability-process.enum';
import { Traceability } from '../../models/traceability.model';
import { TraceabilityService } from '../../services/traceability.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  otpForm: FormGroup;
  loading = false;
  submitted = false;
  otpRequired = false;
  returnUrl: string;
  error = '';
  isShow: boolean = false;
  sessionManagerStrategy: string;
  readonly ecobonusFlag = environment.ecobonusFlag;
  readonly bandiIncentiviFlag = environment.bandiIncentiviFlag;
  readonly baseHref = environment.baseHref;
  // readonly sessionManagerStrategy = environment.sessionManager;
  readonly urlEcobonus = environment.urlEcobonus;
  readonly faq: boolean = environment.features.faq;
  readonly backendUrl = environment.apiUrl;
  it: Language = { id: '1', code: LanguageCode.IT, description: 'IT' };
  en: Language = { id: '2', code: LanguageCode.EN, description: 'EN' };
  languages: Language[] = [];
  currentLanguage: string;

  private otpToken;

  private samlLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private traceService: TraceabilityService,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    public translate: TranslateService,
    private cryptoService: CryptoService,
    private redirectService: RedirectService,
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) {
    this.languages.push(this.it);
    this.languages.push(this.en);
  }

  setSamlWorking(value: boolean | null) {
    if (value === null) {
      value = this.samlLoading;
    }

    this.samlLoading = value;

    if (!value) {
      this.spinner.hide();
    } else {
      this.spinner.show();
    }
  }

  ngOnInit() {
    this.currentLanguage = LanguageCode.IT;
    this.translate.use(LanguageCode.IT.toLowerCase());

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.otpForm = this.formBuilder.group({
      code: ['', Validators.required]
    });

    // reset login status
    // this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.sessionManagerStrategy = this.route.snapshot.queryParams['sessionManagerStrategy'] || '/';
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (params.get('one-time-token')) {
        this.authenticationService.setToken(null, params.get('one-time-token'));
        this.authenticationService.refreshToken()
          .pipe(
            tap(() => {
              this.redirectService.navigate(this.returnUrl);
            })
          );
        return;
      }

      /*
      1. Variabile di samlloading su false
      2. La metto a true sulla chiamata (usare progresspinner)
      3. La rimetto su False dopo la chiamata
      */
      if (params.get("samlToken")) {
        this.setSamlWorking(true);

        this.loginSaml(params.get("samlToken")).pipe(
          tap(res => {
            this.afterLogin({
              access_token: res.access_token,
              refresh_token: res.refresh_token
            });
            this.setSamlWorking(false);
          }),
          catchError(e => {
            console.log("Encountered error logging in, aborting...");
            this.setSamlWorking(false);
            return throwError(e);
          })
        ).subscribe();
      }

      if (params.get('token') || params.get('refresh_token')) {
        this.authenticationService.setToken(params.get('token'), params.get('refresh_token'));
        // this.redirectService.navigate(this.returnUrl);

        this.afterLogin({ access_token: params.get('token'), refresh_token: params.get('refresh_token') });
      }
    });

    this.setSamlWorking(null);
  }



  /**
  * Exchange the saml token with an access token, for the
  * request are needed a grant type and a subject token type
  * @param samlToken
  * @returns the accesso token
  */
  loginSaml(samlToken: string): Observable<AuthenticationCookie> {
    const payload = new FormData();
    payload.append(
      "grant_type",
      "urn:ietf:params:oauth:grant-type:token-exchange"
    );
    payload.append(
      "subject_token_type",
      "urn:ietf:params:oauth:token-type:jwt"
    );
    payload.append("subject_token", samlToken);

    return this.getToken(payload);
  }

  private getToken(payload: FormData): Observable<AuthenticationCookie> {
    const headers = new HttpHeaders({
      Authorization: `Basic dGxzLWNsaWVudC1pZDp0bHMtY2xpZW50LXNlY3JldA==`
    });

    return this.http.post<AuthenticationCookie>(`${environment.apiUrl}/oauth/token`, payload, { headers })
      .pipe(
        tap(res => {
          // login successful if there's a jwt token in the response
          if (res && res.access_token) {
            this.authenticationService.setToken(res.access_token);
          }
        }),
        catchError((e) => {

          return throwError(e);
        })
      );
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  get o() {
    return this.otpForm.controls;
  }

  get loginBackground(): { [p: string]: string } {
    return { background: `url(${this.baseHref}/assets/images/login-bg.jpg) center bottom` };
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid && this.isShow) {
      return;
    }

    this.error = '';
    this.loading = true;
    // const password = this.cryptoService.encryptToString(this.loginForm.get('password').value);
    const password = this.loginForm.get('password').value;
    this.authenticationService.login(this.loginForm.get('username').value, password, this.sessionManagerStrategy)
      .pipe(first())
      .subscribe(
        data => {
          this.afterLogin(data);
        },
        error => {
          if (error.status === 403 && error.error != null && error.error.error === 'otp_required') {
            this.otpToken = error.error.otp_token;
            this.submitted = false;
            this.otpRequired = true;
            this.loading = false;
            return;
          } else if (error.status === 409 && error.error != null && error.error.error === 'concurrent_login_detected') {
            this.submitted = false;
            this.error = 'È stato rilevato una sessione attiva su un altro dispositivo. Tutte le sessioni sono state terminate. Reinserire le credenziali per accedere.';
            this.loading = false;
            return;
          } else if (error.status === 400 && error.error != null && error.error.error_description === 'Bad credentials' && this.loginForm.get('password').value == "") {
            this.isShow = true;
            this.loading = false;
            this.submitted = false;
            return;
          } else if (error.status === 308) {
            window.location.href = error.error + "?returnUrl=" + this.returnUrl;
            return;
          }
          this.error = 'Credenziali errate';
          this.loading = false;
        });
  }

  onSubmitOtp() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.otpForm.invalid) {
      return;
    }

    this.error = '';
    this.loading = true;
    // const password = this.cryptoService.encryptToString(this.loginForm.get('password').value);
    this.authenticationService.loginOtp(this.otpToken, this.otpForm.get('code').value)
      .pipe(first())
      .subscribe(
        data => {
          this.afterLogin(data);
        },
        error => {
          // console.error(error);
          if (error.status === 409 && error.error != null && error.error.error === 'concurrent_login_detected') {
            this.submitted = false;
            this.error = 'È stato rilevato una sessione attiva su un altro dispositivo. Tutte le sessioni sono state terminate. Reinserire le credenziali per accedere.';
            this.loading = false;
            return;
          }
          this.error = 'Codice errato';
          this.loading = false;
        });
  }

  private afterLogin(data): void {
    const trace = new Traceability();

    trace.activity = 'Log-in';
    trace.process = TraceabilityProcessEnum.USER_ACCESS;

    this.traceService.addTraceability(trace).subscribe(res => {
      this.redirect(data);
    }, error => {
      this.redirect(data);
    });
  }

  private redirect(data): void {
    if (this.returnUrl == null || this.returnUrl == undefined || this.returnUrl == "") {
      this.returnUrl = "/";
    }

    if (this.redirectService.external(this.returnUrl)) {
      let url = this.returnUrl;

      if (!this.bandiIncentiviFlag && this.sessionManagerStrategy !== "COOKIES") {
        url = `${url}?token=${data.access_token}&refresh_token=${data.refresh_token}`;
      }

      window.location.href = url;
    } else {
      this.redirectService.navigate(this.returnUrl);
    }
  }

  changeLanguage(): void {
    // @ts-ignore
    this.translate.use(this.currentLanguage.toLowerCase());
  }
}
