import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Company} from '../../../models/company.model';
import {GeneralSettingValue} from '../../../models/general-setting-value.model';
import {GeneralSetting} from '../../../models/general-setting.model';
import {User} from '../../../models/user.model';
import {Authorities} from '../../../enums/security/authorities.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {GeneralSettingValueService} from '../../../services/general-setting-value.service';
import {CompanyService} from '../../../services/company.service';
import {UserService} from '../../../services/user.service';
import {NavigationMenu} from '../../../enums/navigation-menu.enum';
import {NavigationService} from '../../../services/navigation.service';
import {TranslateService} from '@ngx-translate/core';
import {mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {AuthenticationService, AuthorizationService} from '@pwc/security';

@Component({
  selector: 'app-general-settings-detail',
  templateUrl: './general-settings-detail.component.html',
  styleUrls: ['./general-settings-detail.component.scss']
})
export class GeneralSettingsDetailComponent implements OnInit {
  registerForm: FormGroup;
  id: any;
  permissions: string;
  generalSettingList: GeneralSetting[] = [];
  userList: User[] = [];
  companyList: Company[] = [];
  generalSetting: GeneralSettingValue;
  mode: string;
  initialized = false;
  submitted = false;
  loading = false;
  items: any[] = [];
  userProfiling: boolean = false;
  societyProfiling: boolean = false;


  requiredAuthorities = {
    read: Authorities.ROLE_GENERAL_SETTING_VALUE_READ,
    create: Authorities.ROLE_GENERAL_SETTING_VALUE_CREATE,
    update: Authorities.ROLE_GENERAL_SETTING_VALUE_UPDATE,
    delete: Authorities.ROLE_GENERAL_SETTING_VALUE_DELETE,
    admin: {
      read: Authorities.ROLE_PARENT_COMPANY_READ,
      create: Authorities.ROLE_PARENT_COMPANY_CREATE,
      update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
      delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
    }
  };

  messages: any;

  private _ref: any;

  @ViewChild('alertModal', {static: true}) alertModal: any;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private navigationService: NavigationService,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private service: GeneralSettingValueService,
              private companyService: CompanyService,
              private userService: UserService,
              private translateService: TranslateService,
              private authorizationService: AuthorizationService) {
    this._ref = this;
    this.initTranslations();
  }

  initTranslations() {
    if (this.translateService == null) {
      return;
    }

    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
      // console.log('messages', this.messages);
    });
  }

  get f() {
    return this.registerForm.controls;
  }

  ngOnInit() {
    this.authenticationService.checkAdmin();
    this.permissions = 'rw';
    this.navigationService.setActiveMenu(NavigationMenu.SETTINGS);

    this.generalSetting = new GeneralSettingValue(null, '', null, null, null, null, null, null);
    this.initValidation();
    if (this.router.url.endsWith('/new')) {
      this.mode = 'new';
      this.initialized = true;
      this.loadStaticSelectData();
    } else {
      this.mode = 'edit';
      this.loadStaticSelectData();
      this.route.params.subscribe(params => {
        // console.log('params', params);
        this.id = +params.id; // (+) converts string 'id' to a number
        this.permissions = params.permissions;

        // load details
        this.service.getDetail(this.id).subscribe(detail => {
          // console.log('detail', detail);
          this.generalSetting = detail;
          this.checkVisibilityProfiling();
          this.initValidation();
          this.initialized = true;
        });

      });
    }
  }

  loadStaticSelectData() {
    this.authorizationService.checkAuthorities(this.requiredAuthorities.admin.create)
      .subscribe((authorized: boolean) => {
        this.userService.loadService(authorized);
        this.userService.filteredList$.subscribe(list => this.userList = list);

        this.companyService.loadService();
        this.companyService.filteredList$.subscribe(list => this.companyList = list);

        this.service.getGeneralSettingKey(authorized ? null : false).subscribe(settings => {
          console.log('settings', settings);
          if (!authorized) {
            this.generalSettingList = settings.filter(s => !s.adminProfiling);
          } else {
            this.generalSettingList = settings;
          }

          this.checkVisibilityProfiling();
        });
      });

    // this.userService.getAll().subscribe(users => {
    //     console.log('users', users);
    //     this.userList = users;
    //     console.log('this.userList', this.userList);
    // });
    // this.companyService.getAll().subscribe( list => {
    //     this.companyList = list;
    // });
    this.loadSettingsKeys();
  }

  loadSettingsKeys(userProfiling?: boolean, societyProfiling?: boolean) {

  }

  checkVisibilityProfiling() {
    if (this.generalSetting.settingId != null) {
      const currSetting = this.generalSettingList.filter(s => s.id === this.generalSetting.settingId);
      this.userProfiling = currSetting.length > 0 && currSetting[0].userProfiling != null && currSetting[0].userProfiling;
      this.societyProfiling = currSetting.length > 0 && currSetting[0].societyProfiling != null && currSetting[0].societyProfiling;
    } else {
      this.userProfiling = false;
      this.societyProfiling = false;
    }
  }

  initValidation() {
    this.registerForm = this.formBuilder.group({
      editSettingSelect: [this.generalSetting.settingId, Validators.required],
      editValueText: [this.generalSetting.value, Validators.required],
      editCompanySelect: [null, []],
      editUserSelect: [null, []],
    });
  }

  goTolist() {
    this.router.navigateByUrl('general-settings');
  }

  readonly() {
    return this.permissions === 'r';
  }

  onChangeSettingKey($event) {
    this.checkVisibilityProfiling();
  }

  save() {
    // console.log('this.registerForm.invalid', this.registerForm.invalid);
    this.submitted = true;
    // check validation
    if (this.checkFormValid()) {
      if (this.mode === 'new') {
        this.service.add(this.generalSetting).subscribe((res: GeneralSettingValue) => {
          // console.log('RISP:', res);
          this.id = res.id;
          // this.loader.hide();
          this.openAlert(0, 'success', this.messages.savingSuccess);
          this.loading = false;
          //
        }, (err) => {
          // console.log(err);
          if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
            const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
            this.openAlert(0, 'error', msg);
          } else {
            this.openAlert(0, 'error', this.messages.errorSaving);
          }
          this.loading = false;
        });
      } else if (this.mode === 'edit') {
        // this.generalSetting.indexType = null;
        // console.log('data:', this.generalSetting);
        this.service.update(this.generalSetting).subscribe((res: GeneralSettingValue) => {
          // console.log('RISP:', res);
          this.id = res.id;
          // this.loader.hide();
          this.openAlert(0, 'success', this.messages.savingSuccess);
          this.loading = false;
          //
        }, (err) => {
          // console.log(err);
          if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
            const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
            this.openAlert(0, 'error', msg);
          } else {
            this.openAlert(0, 'error', this.messages.errorSaving);
          }
          this.loading = false;
        });
      }
    }
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      this.goTolist();
    }
  }

  checkFormValid() {
    return !this.registerForm.invalid;
  }
}
