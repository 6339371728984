<div class="row login-page" *ngIf="languages.length>0" [ngStyle]="loginBackground">
  <div class="col-xl-4 col-lg-5 col-md-6 col-sm-6 ml-auto mr-auto login-col">
    <section class="login-box">
      <div class="row">
        <div class="col align-self-center">
          <div class="card login-card">
            <div class="card-title">
              <!-- <div class="d-inline-block card-title-content" *ngIf="languages.length>0">
                <ng-select [items]="languages"
                           name="languageSelector"
                           [searchable]="false"
                           (change)="changeLanguage()"
                           bindValue="code"
                           bindLabel="description"
                           class="custom"
                           [clearable]="false"
                           [(ngModel)]="currentLanguage">
                </ng-select>
              </div> -->
              <!-- <div class="col lang-box" *ngIf="true">
                <select class="custom-select" id="gender2" style="float: right">
                  <option selected value="it">IT</option>
                  <option value="en">EN</option>
                </select>
              </div> -->
            </div>
            <div class="card-body">
              <div *ngIf="ecobonusFlag">
                <div class="col welcome-box mt-4 lead text-center">
                  Piattaforma
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/login-ecobonus.png'" />
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-login.png'" />
                </div>
              </div>
              <div *ngIf="bandiIncentiviFlag">
                <div class="col welcome-box mt-4 lead text-center">
                  Piattaforma
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-bandi-e-incentivi.png'" />
                </div>
                <div class="col image-box mt-2">
                  <img class="image-login" [src]="baseHref + '/assets/images/logo-login.png'" />
                </div>
              </div>
              <div *ngIf="!ecobonusFlag && !bandiIncentiviFlag" class="col image-box mt-2">
                <!-- <img class="image-login" [src]="baseHref + '/assets/images/digital-suite-tls.png'"/> -->
                <span class="title">Legal Digital Suite</span>
              </div>
              <div class="col welcome-box mb-4">
                <h3><span>Benvenuto</span></h3>
                <span class="subtitle">Inserisci le credenziali ricevute</span>
              </div>
              <div class="col form-box">
                <form *ngIf="!otpRequired" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                    <label for="username">Username</label>
                    <input type="text" maxlength="255" formControlName="username" class="form-control" id="username"
                      name="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="off" />
                    <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                      <div *ngIf="f.username.errors.required"><span>Username is required</span></div>
                    </div>
                  </div>
                  <div *ngIf="isShow" class="form-group">
                    <label for="password">Password</label>
                    <input type="password" formControlName="password" class="form-control" id="password" name="password"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="off" />
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required"><span>Password is required</span></div>
                    </div>
                  </div>
                  <div class="form-check mb-4">
                    <!--                    <input type="checkbox" class="form-check-input" id="exampleCheck1">-->
                    <!--                    <label class="form-check-label" for="exampleCheck1">Resta collegato</label>-->
                    <div class="box-link-lost-password">
                      <a routerLink="/forgot-password"><span>Hai dimenticato la password?</span></a>
                    </div>
                  </div>
                  <div class="form-group">
                    <br>
                    <button [disabled]="loading" class="btn btn-primary login-btn"><span>Login</span></button>
                    <div class="text-center mt-3" *ngIf="ecobonusFlag && faq">
                      <a [href]="urlEcobonus + '/login-faq'" target="_blank"><span>Hai bisogno di aiuto?</span></a>
                    </div>
                    <img *ngIf="loading" class="pl-2"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                </form>

                <form *ngIf="otpRequired" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
                  <div class="form-group">
                    <label for="code"><span>Inserisci il codice di accesso ricevuto via e-mail</span></label>
                    <input type="text" maxlength="255" formControlName="code" id="code" name="code" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && o.code.errors }" />
                    <div *ngIf="submitted && o.code.errors" class="invalid-feedback">
                      <div *ngIf="o.code.errors.required"><span translate>login.field.mandatory-code</span></div>
                    </div>
                  </div>
                  <div class="form-group">
                    <br>
                    <button [disabled]="loading" class="btn btn-primary login-btn"><span>Login</span></button>
                    <div class="text-center mt-3" *ngIf="ecobonusFlag && faq">
                      <a [href]="urlEcobonus + '/login-faq'" target="_blank"><span>Hai bisogno di aiuto?</span></a>
                    </div>
                    <img *ngIf="loading" class="pl-2"
                      src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  </div>
                  <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                </form>
              </div>
              <!--              <div class="col welcome-box" *ngIf="false">-->
              <!--                <hr>-->
              <!--                <span>Non sei ancora registrato? <a href="#">REGISTRATI</a></span>-->

              <!--              </div>-->
              <div class="col welcome-box" *ngIf="ecobonusFlag">
                <br>
                <img alt="PwC TLS Avvocati e commercialisti" class="logo-footer ml-auto"
                  [src]="baseHref + '/assets/images/logo-footer.svg'">
              </div>
              <div class="col welcome-box" *ngIf="!ecobonusFlag">
                <br>
                <img alt="PwC TLS Avvocati e commercialisti" class="logo-footer ml-auto"
                  [src]="baseHref + '/assets/images/logo_footer_v2.PNG'">
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>

  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#d04a02" type="ball-clip-rotate" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
</div>