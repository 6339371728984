import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TraceabilityService } from '../../../services/traceability.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '@pwc/security';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  showSuccess: boolean = false;
  appReturnUrl: string;
  readonly ecobonusFlag = environment.ecobonusFlag;
  readonly bandiIncentiviFlag = environment.bandiIncentiviFlag;
  readonly baseHref = environment.baseHref;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private traceService: TraceabilityService,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.forgotPassword(this.loginForm.get('email').value)
      .pipe(first())
      .subscribe(
        (res): any => {
          this.loading = false;
          // if (res === false) {
          //   this.showSuccess = false;
          //   // this.error = 'Email non riconosciuta';
          //   this.error = 'Si è verificato un errore';
          // } else {
          this.showSuccess = true;
          this.error = '';
          // }
        },
        error => {
          console.error(error);
          this.error = 'Si è verificato un errore';
          this.loading = false;
        });
  }

  goToLoginPage() {
    const getCookieValue = (name) => (document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '');
    this.appReturnUrl = getCookieValue("appReturnUrl");
    document.cookie = 'appReturnUrl=; Max-Age=0';
    if (this.appReturnUrl) {
      window.location.href = this.appReturnUrl;
    } else {
      window.location.href = environment.meUrl;
    }
  }
}
