import {Injectable} from '@angular/core';
import {Team} from '../components/teams/models/team';
import {TeamResource} from '../components/teams/models/api/team-resource';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {ExcelExportService} from './excel-export.service';
import {faSuperscript} from '@fortawesome/free-solid-svg-icons';
import {isNullOrUndefined} from 'util';
import {Observable} from 'rxjs';
import { HttpService } from '@pwc/common';


@Injectable({
  providedIn: 'root'
})
export class TeamsService extends HttpService<TeamResource, Team> {

  protected baseUrl: string = environment.apiUrl;
  protected endpoint: string = 'team';

  public sortColumn: string;
  public sortDirection: string;

  constructor(http: HttpClient, private excelService: ExcelExportService) {
    super(http);
  }

  public getByUserid(userId: number, withChild: boolean = false): Observable<Team[]> {
    return this.http.get<Team[]>(`${this.baseUrl}/${this.endpoint}/byUserId/${userId}?withChild=${withChild}`);
  }

  public compare(v1, v2) {
    return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
  }

  public sort(list: Team[], column: string, direction: string): Team[] {
    if (direction === '') {
      return list;
    } else {
      const columnElems = column.split('.');
      return [...list].sort((a, b) => {
        let aVal = a;
        let bVal = b;
        columnElems.map(x => {
          aVal = !isNullOrUndefined(aVal) ? aVal[x] : '';
          bVal = !isNullOrUndefined(bVal) ? bVal[x] : '';
        });
        const res = this.compare(aVal, bVal);
        return direction === 'asc' ? res : -res;
      });
    }
  }


  public exportAsExcel(exportedList: any[]) {
    this.excelService.exportAsExcelFile(exportedList, 'Data');
  }

  convertResourceToModel(resource: TeamResource) {
    const model = {
      id: null,
      ...resource
    };

    return {
      ...model,
      selected: false
    };
  }

  convertModelToResource(model: Team): TeamResource {
    let resource = {...model};
    delete resource.selected;
    return resource;
  }
}

