import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {User} from '../../../models/user.model';
import {NgbdSortableHeader, SortEvent} from '../../../directives/sortable.directive';
import {UserService} from '../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {GenericType} from '../../../models/generic-type.model';
import {downloadBlob, mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {Authorities} from '../../../enums/security/authorities.enum';
import {AuthorizationService} from '@pwc/security';
import {piClear, piExport, PwcIconsLibrary} from '@pwc/icons';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {UserPaginatedService} from '../../../services/user-paginated.service';
import {DataTableComponent, SortDirection} from '@pwc/ui';
import {CdkColumnDef} from '@angular/cdk/table';
import {ApiResource, PageableResponse, StringUtils} from '@pwc/common';
import {PageableUserResource} from '../../../models/pageable-user-resource';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  dataSet: PageableUserResource[] = [];

  total: number;
  selectAll: boolean;
  selectedList: PageableUserResource[] = [];
  filteredList: User[] = [];
  selectedCount: number = 0;

  exportInProgress: boolean = false;

  messages: any;
  defaultListType: GenericType[] = [];

  requiredAuthorities = {
    company: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    },
    parentCompany: {
      read: Authorities.ROLE_PARENT_COMPANY_READ,
      create: Authorities.ROLE_PARENT_COMPANY_CREATE,
      update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
      delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
    }
  };
  isAdmin: boolean = false;

  private searchTriggerSubject: Subject<void> = new Subject<void>();
  private searchTrigger$: Observable<void> = this.searchTriggerSubject.asObservable();

  @ViewChild('dataTable') dataTable: DataTableComponent<PageableUserResource>;
  @ViewChildren(CdkColumnDef) customColumnDefs: QueryList<CdkColumnDef>;

  initialized: boolean = false;

  columns = [];
  customColumns: string[] = [
    'id',
    'username',
    'lastName',
    'firstName',
    'enabled',
    'actions',
  ];

  readonly baseHref = environment.baseHref;

  sortDirection: SortDirection = 'desc';
  sortType: string;
  sortReverse: number = 0;

  pagination: PageableResponse<ApiResource[]>;
  currentPage = 0;
  pageSize = 10;

  @Output() showLoading = new EventEmitter<boolean>();
  @Output() openDetail = new EventEmitter<object>();
  @ViewChild('alertModalList', {static: true}) alertModal: any;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: UserService,
              private userPaginatedService: UserPaginatedService,
              private translateService: TranslateService,
              private authorizationService: AuthorizationService,
              private library: PwcIconsLibrary,
              private changeDetector: ChangeDetectorRef) {
    this.library.registerIcons([
      piExport,
      piClear
    ]);

    this.initTranslations();
    this.translateService.onLangChange.subscribe(event => {
      // console.log('onLangChange', event);
      // this.loadDataByLang();
      this.initTranslations();
    });
  }

  initTranslations() {
    if (this.translateService == null) {
      return;
    }

    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
      // console.log('messages', this.messages);
      this.loadDataByLang();
    });
  }

  loadDataByLang() {
    this.defaultListType = [
      new GenericType(1, 'YES', this.messages != null ? this.messages.yesAnswer : 'YES'),
      new GenericType(1, 'NO', this.messages != null ? this.messages.noAnswer : 'NO'),
    ];
  }

  ngOnInit(): void {
    this.initSearch();

    this.subscriptions.push(
      this.userPaginatedService.pagination$
        .subscribe((pagination: PageableResponse<PageableUserResource[]>) => {
          this.pagination = {...pagination};
        })
    );

    this.authorizationService.checkAuthorities(this.requiredAuthorities.parentCompany.create)
      .subscribe((authorized: boolean) => {

        this.isAdmin = authorized;
        this.onSearch();
      });

    this.selectAll = false;
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscriptions == null) {
      return;
    }

    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  private initSearch(): void {
    this.subscriptions.push(
      this.searchTrigger$
        .pipe(
          debounceTime(200),
          switchMap(() => {
            const search: Partial<PageableUserResource> = {
              page: this.currentPage,
              size: this.pageSize
            };

            if (!StringUtils.isEmpty(this.service.searchID) && !isNaN(+this.service.searchID)) {
              search.id = +this.service.searchID;
            }

            if (!StringUtils.isEmpty(this.service.searchUsername)) {
              search.username = this.service.searchUsername;
            }

            if (!StringUtils.isEmpty(this.service.searchLastName)) {
              search.lastName = this.service.searchLastName;
            }

            if (!StringUtils.isEmpty(this.service.searchFirstName)) {
              search.firstName = this.service.searchFirstName;
            }

            if (this.service.searchEnabled != null) {
              search.enabled = this.service.searchEnabled;
            }

            //  Pagination
            if (this.sortDirection !== '' && this.sortType != null) {
              search.sort = `${this.sortType},${this.sortDirection}`;
            }

            return this.userPaginatedService.search(search);
          })
        )
        .subscribe((list: PageableUserResource[]) => {
          this.dataSet = [...list];
        })
    );
  }

  onSearch(): void {
    this.searchTriggerSubject.next();
  }

  onPageChange(page: number): void {
    this.currentPage = page - 1;
    this.onSearch();
  }

  onPageSizeChange(size: number): void {
    this.pageSize = size;
    this.onSearch();
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onOpenDetail(id: number, permission: string) {
    this.openDetail.emit({id, permission});
  }

  onShowLoading(show: boolean) {
    this.showLoading.emit(show);
  }

  onSort($event: SortEvent): void {
    switch ($event.direction) {
      case 'asc':
        this.sortReverse = 1;
        break;
      case 'desc':
        this.sortReverse = 2;
        break;
      default:
        this.sortReverse = 0;
        break;
    }

    this.sortType = $event.column;
    this.sortDirection = $event.direction;

    this.onSearch();
  }

  selectVisibleRows(selected: boolean) {
    for (const user of this.dataSet) {
      user.selected = selected;
    }

    if (selected) {
      this.selectedList = this.dataSet.filter(u => u.selected);
    } else {
      this.selectedList = [];
    }

    this.selectedCount = this.selectedList.length;
    this.selectAll = selected;
  }

  selectAllRows() {
    return;
  }

  selectRows(id: number, selected: boolean) {
    for (const user of this.dataSet) {
      if (user.id === id) {
        user.selected = selected;
      }
    }

    this.selectedList = this.dataSet.filter(u => u.selected);
    this.selectedCount == this.selectedList.length;
    this.selectAll = false;
  }

  pageChange(page: number) {
    this.selectAll = false;
    this.selectVisibleRows(false);
    this.onPageChange(page);
  }

  resetFilters(): void {
    this.service.searchID = '';
    this.service.searchUsername = '';
    this.service.searchFirstName = '';
    this.service.searchLastName = '';
    this.service.searchTerm = '';
    this.service.searchEnabled = null;

    this.onSearch();
  }

  deleteUser(id: any) {
    if (confirm(this.messages.deleteConfirm)) {
      this.service.deleteUser(id).subscribe(res => {
        this.openAlert(0, 'success', this.messages.deleteSuccess);
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'error', this.messages.errorGeneric);
      });
    }
  }

  deleteMassiveUsers() {
    if (confirm(this.messages.deleteSelectedConfirm)) {
      this.service.deleteAllUsers(this.selectedList.map(u => u.id)).subscribe(res => {
        this.openAlert(0, 'success', this.messages.deleteSuccess);
      }, (err) => {
        // console.log(err);
        this.openAlert(0, 'error', this.messages.errorGeneric);
      });
    }
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      this.onSearch();
      // this.service.loadService();
    }
  }

  export(selected: boolean = false) {
    this.exportInProgress = true;
    this.service.exportUsers()
      .pipe(
        tap(() => {
          this.exportInProgress = false;
        })
      )
      .subscribe(blob => {
        downloadBlob(new Blob(['\ufeff', blob]), 'users_export_' + new Date().getTime() + '.csv');
      }, err => {
        console.error(err);
        this.exportInProgress = false;
      });
  }
}
