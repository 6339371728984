import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PwcIconsModule } from '@pwc/icons';
import { CRYPTO_PARAMS, ErrorInterceptor, JwtInterceptor, SecurityModule } from '@pwc/security';
import { UIModule } from '@pwc/ui';
import { CookieService } from 'ngx-cookie-service';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './components/account/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/account/reset-password/reset-password.component';
import { CommonModule } from './components/common/common.module';
import { CompaniesListComponent } from './components/companies/companies-list/companies-list.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompanyDetailComponent } from './components/companies/company-detail/company-detail.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { WidgetCommunicationsComponent } from './components/dashboard/widget-communications/widget-communications.component';
import { WidgetCommunicoComponent } from './components/dashboard/widget-communico/widget-communico.component';
import { WidgetNewsComponent } from './components/dashboard/widget-news/widget-news.component';
import { WidgetPublicationsComponent } from './components/dashboard/widget-publications/widget-publications.component';
import { WidgetRecentActivityComponent } from './components/dashboard/widget-recent-activity/widget-recent-activity.component';
import { WidgetUpcomingActivityComponent } from './components/dashboard/widget-upcoming-activity/widget-upcoming-activity.component';
import { GeneralSettingsDetailComponent } from './components/general-settings/general-settings-detail/general-settings-detail.component';
import { GeneralSettingsListComponent } from './components/general-settings/general-settings-list/general-settings-list.component';
import { GeneralSettingsComponent } from './components/general-settings/general-settings.component';
import { FrameworkLayoutModule } from './components/layout/framework-layout.module';
import { LoginComponent } from './components/login/login.component';
import { AlertComponent } from './components/modals/alert/alert.component';
import { ModalComponent } from './components/modals/modal/modal.component';
import { TeamsDetailsComponent } from './components/teams/teams-details/teams-details.component';
import { TeamsListComponent } from './components/teams/teams-list/teams-list.component';
import { TeamsComponent } from './components/teams/teams.component';
import { UserDetailWrapperComponent } from './components/users/user-detail-wrapper/user-detail-wrapper.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { UserPermissionsComponent } from './components/users/user-permissions/user-permissions.component';
import { UsersListComponent } from './components/users/users-list/users-list.component';
import { UsersComponent } from './components/users/users.component';
import { NgbdSortableHeader } from './directives/sortable.directive';
import { fakeBackendProvider } from './helpers/backend.interceptor';
import { BooleanPipe } from './helpers/boolean.pipe';
import { IdToStringPipe } from './helpers/id-to-string.pipe';
import { AuthenticationServiceConfig } from './security/authentication-service-config';



@NgModule({
  declarations: [
    AppComponent,
    UsersComponent,
    UsersListComponent,
    UserDetailComponent,
    NgbdSortableHeader,
    BooleanPipe,
    IdToStringPipe,
    UserPermissionsComponent,
    UserDetailWrapperComponent,
    CompaniesComponent,
    CompaniesListComponent,
    CompanyDetailComponent,
    LoginComponent,
    DashboardComponent,
    WidgetCommunicationsComponent,
    WidgetRecentActivityComponent,
    WidgetUpcomingActivityComponent,
    WidgetPublicationsComponent,
    WidgetNewsComponent,
    WidgetCommunicoComponent,
    ModalComponent,
    AlertComponent,
    GeneralSettingsComponent,
    GeneralSettingsListComponent,
    GeneralSettingsDetailComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TeamsListComponent,
    TeamsDetailsComponent,
    TeamsComponent,
  ],
  imports: [BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SecurityModule,
    UIModule,
    PwcIconsModule,
    FrameworkLayoutModule,
    NgIdleKeepaliveModule.forRoot(),
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    NgIdleKeepaliveModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    NgxSpinnerModule
  ],
  providers: [
    { provide: 'FRAMEWORK_BASEURL', useValue: environment.apiUrl },
    { provide: 'API_BASEURL', useValue: environment.apiUrl },
    { provide: 'AUTH_OPTIONS', useClass: AuthenticationServiceConfig, multi: false },
    { provide: APP_BASE_HREF, useValue: environment.baseHref + '/' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: 'SESSION_MANAGER_STRATEGY', useValue: environment.sessionManager },
    {
      provide: CRYPTO_PARAMS, useValue: {
        phrase: environment.encryption.phrase,
        salt: environment.encryption.salt,
        iv: environment.encryption.iv
      }
    },
    CookieService,
    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  // return new TranslateHttpLoader(
  //   http,
  //   environment.baseHref + '/assets/i18n/', // or whatever path you're using
  //   '.json'
  // );
  return new TranslateHttpLoader(http);
}

