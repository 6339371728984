import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Company} from '../../../models/company.model';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '../../../services/company.service';
import {NavigationMenu} from '../../../enums/navigation-menu.enum';
import {NavigationService} from '../../../services/navigation.service';
import {CountriesService} from '../../../services/countries.service';
import {Country} from '../../../models/country.model';
import {AtecoService} from '../../../services/ateco.service';
import {Ateco} from '../../../models/ateco.model';
import {GenericSelector} from '../../../models/generic-selector.model';
import {BussSelService} from '../../../services/buss-sel.service';
import {TranslateService} from '@ngx-translate/core';
import {mapTranslateMessages, tranlastionKeys} from '../../../helpers/generic-utils';
import {YesNoEnum} from '../../../enums/yes-no.enum';
import {Authorities} from '../../../enums/security/authorities.enum';
import {sort} from '../../../helpers/list-helper';
import {AuthenticationService, AuthorizationService} from '@pwc/security';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss']
})
export class CompanyDetailComponent implements OnInit {
  companyForm: FormGroup;
  id: number;
  permissions: string;
  data: Company;
  parentCompanyList: Company[] = [];
  mode: string;
  countries: Country[] = [];
  industries: Ateco[] = [];
  businessSelector: GenericSelector[] = [];
  loading: boolean = false;
  initialized: boolean = false;
  submitted: boolean = false;
  booleanList: any[] = [];
  yesnoList: any[] = [];
  YesNoEnum = YesNoEnum;
  canEditParentCompany: boolean = false;

  @ViewChild('alertModal', {static: true}) alertModal: any;

  requiredAuthorities = {
    company: {
      read: Authorities.ROLE_COMPANY_READ,
      create: Authorities.ROLE_COMPANY_CREATE,
      update: Authorities.ROLE_COMPANY_UPDATE,
      delete: Authorities.ROLE_COMPANY_DELETE,
    },
    parentCompany: {
      read: Authorities.ROLE_PARENT_COMPANY_READ,
      create: Authorities.ROLE_PARENT_COMPANY_CREATE,
      update: Authorities.ROLE_PARENT_COMPANY_UPDATE,
      delete: Authorities.ROLE_PARENT_COMPANY_DELETE,
    }
  };

  messages: any = new Object();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private companyService: CompanyService,
              private navigationService: NavigationService,
              private businessSelectorService: BussSelService,
              private countriesService: CountriesService,
              private atecoService: AtecoService,
              private translateService: TranslateService,
              private authorizationService: AuthorizationService,
              private authenticationService: AuthenticationService) {
    this.initTranslations();
  }

  initTranslations() {
    this.loadAuthorities();

    if (this.translateService == null) {
      return;
    }
    this.translateService.get(tranlastionKeys).subscribe(translated => {
      this.messages = mapTranslateMessages(translated);
      // console.log('messages', this.messages);
      this.loadDataByLang();
    });

    this.translateService.onLangChange.subscribe(event => {
      // console.log('onLangChange', event);
      this.loadDataByLang();
      // this.initTranslations();
    });
  }

  ngOnInit() {
    this.loadDataByLang();
    this.authenticationService.checkAdmin();
    this.booleanList = [{value: true, text: 'generic.text.yes-answer'}, {value: false, text: 'generic.text.no-answer'}];
    this.yesnoList = [{value: YesNoEnum.YES, text: 'generic.text.yes-answer'}, {value: YesNoEnum.NO, text: 'generic.text.no-answer'}];
    this.navigationService.setActiveMenu(NavigationMenu.SETTINGS);
    this.data = new Company(null, '', '', '', '', '', '', '', '', false, null, null);
    if (this.router.url.endsWith('/new')) {
      this.mode = 'new';
      this.initialized = true;
      this.countriesService.getAll().subscribe(countries => {
        this.countries = countries;
        // this.data.countryTaxResidenceId
      });
      this.atecoService.getAll().subscribe(industries => {
        this.industries = industries;
      });
      this.businessSelectorService.getAll().subscribe(selectors => {
        this.businessSelector = sort(selectors, 'code', 'asc');
      });
      this.companyService.getParentCompanies().subscribe(list => {
        this.parentCompanyList = list;
      });
    } else {
      this.mode = 'edit';
      this.loading = true;
      this.countriesService.getAll().subscribe(countries => {
        // console.log('countries', countries);
        this.countries = countries;
      });
      this.atecoService.getAll().subscribe(industries => {
        this.industries = industries;
      });
      this.businessSelectorService.getAll().subscribe(selectors => {
        this.businessSelector = selectors;
      });
      this.companyService.getParentCompanies().subscribe(list => {
        this.parentCompanyList = this.data && this.data.id ? list.filter(c => c.id !== this.data.id) : list;
      });
      this.route.params.subscribe(params => {
        // console.log('params', params);
        this.id = +params.id; // (+) converts string 'id' to a number
        this.permissions = params.permissions;
        // load data
        this.companyService.getCompany(this.id).subscribe(company => {
          this.data = company;
          this.parentCompanyList = this.parentCompanyList.filter(c => c.id !== this.data.id);
          this.loading = false;
          this.initialized = true;
          this.initValidation();
        }, (err) => {
          // console.log(err);
          this.openAlert(0, 'error', 'Si è verificato un errore nel caricamento');
          this.loading = false;
          // this.goTolist();
        });
      });
    }

    this.initValidation();
    this.formControlValueChanged();
  }

  getCountryDesc(id) {
    return this.countries.filter(c => c.id === id).length > 0 ? this.countries.filter(c => c.id === id)[0].description : '';
  }

  getTypeBusinessAccordingDesc(id) {
    return this.industries.filter(c => c.id === id).length > 0 ? this.industries.filter(c => c.id === id)[0].description : '';
  }

  getBusinessSelectorDesc(id) {
    return this.businessSelector.filter(c => c.id === id).length > 0 ? this.businessSelector.filter(c => c.id === id)[0].description : '';
  }

  getParentCompanyDesc(id) {
    return this.parentCompanyList.filter(c => c.id === id).length > 0 ? this.parentCompanyList.filter(c => c.id === id)[0].name : '';
  }

  loadDataByLang() {
    this.booleanList = [];
    this.booleanList = [{value: true, text: this.messages.yesAnswer}, {value: false, text: this.messages.noAnswer}];
    this.yesnoList = [];
    this.yesnoList = [{value: YesNoEnum.YES, text: 'generic.text.yes-answer'}, {value: YesNoEnum.NO, text: 'generic.text.no-answer'}];
    // console.log('booleanList', this.booleanList);
  }

  private initValidation(): void {
    this.companyForm = this.formBuilder.group({
      vatNumber: [this.data.vatNumber, Validators.required],
      name: [this.data.name, Validators.required],
      country: [this.data.countryTaxResidenceId, Validators.required],
      birthCountry: [this.data.countryEstablishmentId, Validators.required],
      address: [this.data.address, Validators.required],
      type: [this.data.typeBusinessAccordingId, Validators.required],
      industry: [this.data.mainBusinessSectorId, Validators.required],
      group: [this.data.membershipInGroup],
      parentCompanyId: [this.data.parentCompanyId],
      isParentCompany: [this.data.isParentCompany, Validators.required]
    });
    this.formControlValueChanged();
  }

  formControlValueChanged() {
    this.companyForm.get('group').valueChanges.subscribe(
      (grouped: boolean) => {
        if (grouped) {
          this.companyForm.get('parentCompanyId').setValidators([Validators.required]);
        } else {
          this.companyForm.get('parentCompanyId').clearValidators();
        }
      });
    this.companyForm.get('isParentCompany').valueChanges.subscribe(
      (isParent: YesNoEnum) => {
        if (isParent === YesNoEnum.NO) {
          // this.companyForm.get('parentCompanyId').setValidators([Validators.required]);
          this.companyForm.get('group').setValidators([Validators.required]);
        } else {
          this.companyForm.get('parentCompanyId').clearValidators();
          this.companyForm.get('group').clearValidators();
        }
      });
  }

  goTolist() {
    this.router.navigateByUrl('companies');
  }

  get readonly() {
    return this.permissions === 'r';
  }

  get f() {
    return this.companyForm.controls;
  }

  save() {
    this.submitted = true;
    console.log('this.companyForm.invalid', this.companyForm.invalid);
    if (!this.companyForm.invalid) {
      // this.data = new Company(this.mode === 'new' ? null : this.id, this.f.name.value, this.f.vatNumber.value, this.f.country.value, this.f.address.value, this.f.birthCountry.value, this.f.type.value, this.f.industry.value, this.f.group.value, this.f.isParentCompany.value, this.f.parentCompany.value);
      if (this.data.isParentCompany === YesNoEnum.YES) {
        this.data.parentCompanyId = null;
        this.data.membershipInGroup = false;
      } else if (!this.data.membershipInGroup) {
        this.data.parentCompanyId = null;
      }
      console.log('SEND:', this.data);
      if (this.mode === 'new') {
        this.companyService.addCompany(this.data).subscribe(res => {
          // console.log('RISP:', res);
          this.openAlert(0, 'success', this.messages.savingSuccess);
          this.loading = false;
          //
        }, (err) => {
          // console.log(err);
          if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
            const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
            this.openAlert(0, 'error', msg);
          } else {
            this.openAlert(0, 'error', this.messages.errorSaving);
          }
          this.loading = false;
        });
      } else if (this.mode === 'edit') {
        this.companyService.updateCompany(this.data).subscribe((res: Company) => {
          // console.log('RISP:', res);
          this.id = res.id;
          this.openAlert(0, 'success', this.messages.savingSuccess);
          this.loading = false;
          //
        }, (err) => {
          // console.log(err);
          if (err.status === 422 && err.error.type === 'unique_constraint_violated') {
            const msg = `${this.messages.validationFieldDuplicated} ${err.error.details.property}`;
            this.openAlert(0, 'error', msg);
          } else {
            this.openAlert(0, 'error', this.messages.errorSaving);
          }
          this.loading = false;
        });
      }
    }
  }

  openAlert(id: any, type: string, message: string) {
    this.alertModal.openModalAlert(id, type, message);
  }

  callbackApprovation(type: string) {
    if (type === 'success') {
      this.goTolist();
    }
  }

  checkFormValid() {
    if (!this.f.name.invalid && !this.f.vatNumber.invalid && !this.f.country.invalid && !this.f.address.invalid && !this.f.birthCountry.invalid && !this.f.type.invalid && !this.f.industry.invalid) {
      if (!this.f.group.invalid && !this.f.group.value) {
        return true;
      } else {
        return !this.companyForm.invalid;
      }
    } else {
      return false;
    }
  }

  loadAuthorities() {
    this.authorizationService.checkAuthorities(this.requiredAuthorities.parentCompany.create)
      .subscribe((authorized: boolean) => {

        this.canEditParentCompany = authorized;
      });
  }
}
