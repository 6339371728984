import {AuthenticationOptions} from '@pwc/security';
import {environment} from '../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AuthenticationServiceConfig implements AuthenticationOptions {
  cookieDomain = environment.cookieDomain;
  cookiePath = environment.cookiePath;
  frontendUrl = '';
  url = environment.apiUrl;
  loginUrl = environment.meUrl;
  secureFlag = environment.secureFlag;
}
