<div class="card">
  <div class="card-body">
    <h5 class="card-title">
      <span translate>generic.filters.label</span>
      <button class="btn btn-primary btn-sm btn-action-icon ml-0 btn-clear-filter" ngbTooltip="Svuota Filtri"
              (click)="resetFilters()" onmousedown="event.preventDefault();" type="button">
        <pwc-icon name="clear"></pwc-icon>
      </button>
    </h5>

    <div class="d-flex flex-row justify-content-between align-items-end">
      <div class="d-flex flex-row flex-wrap">

        <!--          ID Filter-->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterIDText" translate>
            generic.field.id
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterIDText" id="filterIDText" [(ngModel)]="service.searchID"
            (ngModelChange)="onSearch()"/>
          </div>
        </div>

        <!--          Username filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterUsernameText" translate>
            generic.field.username
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterUsernameText" id="filterUsernameText"
                   [(ngModel)]="service.searchUsername"
                   (ngModelChange)="onSearch()"/>
          </div>
        </div>

        <!--          Last name filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterLastNameText" translate>
            generic.field.last-name
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterLastNameText" id="filterLastNameText"
                   [(ngModel)]="service.searchLastName"
                   (ngModelChange)="onSearch()"/>
          </div>
        </div>

        <!--          First name filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterFirstNameText" translate>
            generic.field.first-name
          </label>
          <div class="form-group form-input-group">
            <input class="form-control form-input no-shadow input-with-border" type="text" maxlength="255"
                   name="filterFirstNameText" id="filterFirstNameText"
                   [(ngModel)]="service.searchFirstName"
                   (ngModelChange)="onSearch()"/>
          </div>
        </div>

        <!--          Enabled filter -->
        <div class="form-group filter-item mr-4">
          <label class="form-input-label no-wrap-label" for="filterEnabledSelect" translate>
            generic.field.enabled
          </label>
          <ng-select [(ngModel)]="service.searchEnabled"
                     (ngModelChange)="onSearch()"
                     id="filterEnabledSelect"
                     name="filterEnabledSelect"
                     [searchable]="false">
            <ng-option *ngFor="let elem of defaultListType"
                       [value]="elem.code === 'YES'">{{ elem.description }}</ng-option>
          </ng-select>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-row">
  <div class="card toolbar-card mb-3" *ngIf="selectedList.length>0">
    <div class="card-body card-table">
      <div class="d-flex p-2">
        <div class="m-2 pr-2 toolbar-box-border-right">
          <button class="btn btn-primary btn-sm btn-action-icon m-1" type="button" ngbTooltip="Elimina"
                  (click)="deleteMassiveUsers()">
            <img [src]="baseHref + '/assets/images/icons/delete.svg'">
          </button>

          <button class="btn btn-primary btn-sm btn-action-icon m-1" type="button" (click)="export(true)"
                  ngbTooltip="{{ 'generic.action.export-excel' | translate }}">
            <img [src]="baseHref + '/assets/images/icons/export.svg'">
          </button>
        </div>
        <div class="m-2 align-self-center">
            <span
              *ngIf="selectedCount>1"> {{ 'generic.selected-items-status.plural' | translate:{n: selectedCount} }} </span>
          <span *ngIf="selectedCount==1"> {{ 'generic.selected-items-status.singolar' | translate }}</span>
          &nbsp;<a [routerLink]="" *ngIf="false" (click)="selectAllRows()" translate>generic.action.select-all-label-status</a>
        </div>
      </div>
    </div>
  </div>
  <button type="button" class="btn btn-ghost ml-auto align-self-end text-nowrap my-3" (click)="export()"
          [disabled]="exportInProgress">
    <!--      <pwc-icon name="export"></pwc-icon>-->
    {{ 'generic.action.export-excel' | translate }}
  </button>
</div>


<div class="card">
  <div class="card-body card-table">
    <table class="table table-striped table-hover">
      <thead>
      <tr>
        <th scope="col" style="width: 15px">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="selectAllRowsCheckBox" #checkall
                   name="selectAllRowsCheckBox" (change)="selectVisibleRows(selectAll)" [(ngModel)]="selectAll">
            <label class="custom-control-label" for="selectAllRowsCheckBox"></label>
          </div>
        </th>
        <th scope="col" sortable="id" (sort)="onSort($event)">ID</th>
        <th scope="col" sortable="username" (sort)="onSort($event)" translate>generic.field.username</th>
        <th scope="col" sortable="lastName" (sort)="onSort($event)" translate>generic.field.last-name</th>
        <th scope="col" sortable="firstName" (sort)="onSort($event)" translate>generic.field.first-name</th>
        <th scope="col" class="d-none d-sm-table-cell" sortable="enabled" (sort)="onSort($event)" translate>
          generic.field.enabled
        </th>
        <th scope="col" style="width: 50px"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let user of dataSet">
        <th scope="row">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" [attr.id]="user.id" [attr.name]="user.id"
                   [checked]="user.selected" [ngModelOptions]="{standalone: true}" [(ngModel)]="user.selected"
                   (change)="selectRows(user.id, user.selected)">
            <label class="custom-control-label" [attr.for]="user.id"></label>
          </div>
        </th>
        <td>
          <ngb-highlight [result]="user.id | idToStringPipe"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="user.username | uppercase" [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="user.lastName | uppercase" [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <ngb-highlight [result]="user.firstName | uppercase" [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td class="d-none d-sm-table-cell">
          <ngb-highlight *ngIf="user.enabled" [result]="messages.yesAnswer"
                         [term]="service.searchTerm"></ngb-highlight>
          <ngb-highlight *ngIf="!user.enabled" [result]="messages.noAnswer"
                         [term]="service.searchTerm"></ngb-highlight>
        </td>
        <td>
          <div style="white-space: nowrap;">
            <button type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="onOpenDetail(user.id, 'r')"
                    ngbTooltip="{{ 'generic.action.show-item' | translate }}">
              <img [src]="baseHref + '/assets/images/icons/visualizza.svg'">
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-action-icon"
                    ngbTooltip="{{ 'generic.action.edit-item' | translate }}"
                    (click)="onOpenDetail(user.id, 'rw')">
              <img [src]="baseHref + '/assets/images/icons/modifica.svg'">
            </button>
            <button type="button" class="btn btn-primary btn-sm btn-action-icon" (click)="deleteUser(user.id)"
                    ngbTooltip="{{ 'generic.action.delete.label' | translate }}">
              <img [src]="baseHref + '/assets/images/icons/elimina.svg'">
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>

    <div class="d-flex justify-content-between p-2 table-paginator">
      <ngb-pagination
        (pageChange)="pageChange($event)"
        style="float: left"
        [maxSize]="5" [rotate]="true" [collectionSize]="pagination?.totalElements" [page]="currentPage + 1"
        [pageSize]="pageSize">
      </ngb-pagination>

      <ng-select [(ngModel)]="pageSize"
                 (ngModelChange)="onSearch()"
                 style="width: auto;"
                 name="pageSize"
                 [clearable]="false"
                 class="custom"
                 autofocus="false"
                 [searchable]="false">
        <ng-option *ngFor="let elem of [ { value: 10 }, { value: 20 }, { value: 50 } ]"
                   [value]="elem.value">{{ elem.value }} {{ 'generic.action.switch-row-per-page' | translate }}
        </ng-option>
      </ng-select>
    </div>

  </div>
</div>

<div class="d-flex flex-row-reverse my-3 p-2">
  <button class="btn btn-primary" routerLink="new">{{ 'generic.action.new' | translate }}</button>
</div>
<app-alert #alertModalList [model]="" (onAnyCloseEmitter)="callbackApprovation($event)"></app-alert>
